<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="h6 primary--text"
            >Datos del encabezado de la OP</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card>
                  <v-card-title class="h6">Proveedor</v-card-title>
                  <v-card-text>
                    <v-row>
                      <!-- Proveedor -->
                      <v-col cols="4" sm="5" md="5" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Proveedor"
                          v-model="proveedor"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- CUIT -->
                      <v-col cols="4" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="CUIT"
                          v-model="cuit"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Tipo IVA -->
                      <v-col cols="4" sm="4" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Tipo IVA"
                          v-model="tipoIva"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Dirección -->
                      <v-col cols="4" sm="5" md="5" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Dirección"
                          v-model="direccion"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Modalidad de pago -->
                      <v-col cols="4" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Modalidad de pago"
                          v-model="modalidadPago"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Destino de pago -->
                      <v-col cols="4" sm="4" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Destino de pago"
                          v-model="destinoPago"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card>
                  <v-card-title class="h6">Comprobante</v-card-title>
                  <v-card-text>
                    <v-row>
                      <!-- Tipo de comprobante -->
                      <v-col cols="4" sm="5" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Tipo de comprobante"
                          v-model="tipoComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Comprobante -->
                      <v-col cols="4" sm="4" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Comprobante"
                          v-model="comprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Fecha -->
                      <v-col cols="3" sm="3" md="2" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha"
                          v-model="fecha"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- fecha contable -->
                      <v-col
                        cols="3"
                        sm="3"
                        md="2"
                        class="py-0"
                        v-if="this.$route.params.isCtaCte"
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha contable"
                          v-model="fechaContable"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!-- Moneda del comprobante -->
                      <v-col
                        cols="12"
                        md="3"
                        class="py-0"
                        v-if="
                          this.paramGralMultimoneda &&
                            this.$route.params.isCtaCte
                        "
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Moneda del comprobante"
                          v-model="monedaComprobante"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Fecha de cotizacion -->
                      <v-col
                        cols="2"
                        md="2"
                        class="py-0"
                        v-if="
                          this.paramGralMultimoneda &&
                            this.$route.params.isCtaCte
                        "
                      >
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          label="Fecha cotización"
                          v-model="fechaCotizacion"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Moneda de consulta -->
                      <v-col
                        cols="12"
                        md="3"
                        class="py-0"
                        v-if="
                          this.paramGralMultimoneda &&
                            this.$route.params.isCtaCte
                        "
                      >
                        <v-autocomplete
                          v-model="monedaConsultaSelected"
                          :items="monedasConsultaItems"
                          item-text="value"
                          item-value="id"
                          return-object
                          label="Moneda de consulta"
                          @change="setComprobanteByMoneda()"
                          outlined
                          dense
                        >
                        </v-autocomplete>
                        <p
                          class="invalidConfigText mt-n5"
                          v-if="this.haveCotiza"
                        >
                          Sin cotización
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-card>
                  <v-card-title class="h6">Datos del comprobante</v-card-title>
                  <v-card-text>
                    <v-row>
                      <!-- Neto -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          prepend-inner-icon="mdi-currency-usd"
                          class="right-input"
                          readonly
                          label="Neto"
                          v-model="importeNeto"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- IVA -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          prepend-inner-icon="mdi-currency-usd"
                          label="IVA"
                          v-model="iva"
                          class="right-input"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Percepción IVA -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          prepend-inner-icon="mdi-currency-usd"
                          label="Percepción IVA"
                          class="right-input"
                          v-model="percepcionIva"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Percepción IIBB -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          prepend-inner-icon="mdi-currency-usd"
                          readonly
                          class="right-input"
                          label="Percepción IIBB"
                          v-model="percepcionIIBB"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Impuestos internos -->
                      <v-col cols="4" sm="4" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          prepend-inner-icon="mdi-currency-usd"
                          filled
                          readonly
                          class="right-input"
                          label="Impuestos internos"
                          v-model="impuestosInternos"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Otros conceptos -->
                      <v-col cols="4" sm="4" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-currency-usd"
                          dense
                          filled
                          readonly
                          class="right-input"
                          label="Otros conceptos"
                          v-model="otrosConceptos"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Total -->
                      <v-col cols="4" sm="4" md="4" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          prepend-inner-icon="mdi-currency-usd"
                          class="right-input"
                          label="Total"
                          v-model="importeTotal"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title class="h6"
            >Listado de comprobantes cancelados</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-data-table
                  :headers="headersComprobantesCancelados"
                  :items="comprobantesCanceladosList"
                  class="elevation-1"
                  hide-default-footer
                  :items-per-page="999"
                  dense
                >
                </v-data-table
              ></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title class="h6"
            >Listado de retenciones</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-data-table
                  :headers="headersRetenciones"
                  :items="retencionesList"
                  :items-per-page="999"
                  class="elevation-1"
                  hide-default-footer
                  dense
                >
                  <template v-slot:[`item.tipoValorNombre`]="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          @click="printRetencion(item.mpValId)"
                          class="blue-text"
                        >
                          {{ item.tipoValorNombre }}
                        </div>
                      </template>
                      <span>Imprimir</span>
                    </v-tooltip>
                  </template>
                </v-data-table></v-col
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title class="h6"
            >Listado de valores</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-data-table
                  :headers="headersValores"
                  :items="valoresList"
                  class="elevation-1"
                  :items-per-page="999"
                  hide-default-footer
                  dense
                >
                </v-data-table
              ></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="7">
        <v-btn
          color="primary"
          class="mt-2 py-4 to-right fontsize text-color=pink"
          align="end"
          outlined
          small
          @click="printOrdenPago()"
        >
          Imprimir
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          color="primary"
          class="mt-2 py-4 to-right fontsize text-color=pink"
          align="end"
          small
          @click="VerEnviosPrevios()"
        >
          Ver envíos previos
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          color="primary"
          class="mt-2 py-4 fontsize text-color=pink"
          align="end"
          small
          :loading="loadingBtn"
          :disabled="mail == '' || loadingBtn"
          @click="enviarMail()"
        >
          Enviar por email
        </v-btn>
      </v-col>
      <div v-if="mail == ''">
        <v-col cols="1" class="pa-0 mt-5 mr-6">
          <v-tooltip right max-width="20%">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                {{ infoIcon }}
              </v-icon>
            </template>
            <span>
              El proveedor no posee email configurado, no es posible enviar.
            </span>
          </v-tooltip>
        </v-col>
      </div>
    </v-row>
    <v-dialog
      v-if="openVerEnviosPrevios"
      v-model="openVerEnviosPrevios"
      max-width="80%"
      persistent
      @keydown.esc="closeVerEnviosPrevios"
    >
      <VerEnviosPreviosOP
        :idEnvios="idEnvios"
        @closeVerEnviosPrevios="closeVerEnviosPrevios"
      ></VerEnviosPreviosOP>
    </v-dialog>
    <v-dialog
      v-if="openModalEnviarOrdenes"
      v-model="openModalEnviarOrdenes"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EnviarOrdenesPagoEmail
        @closeAndReload="closeAndReload"
        :idEntidadFacturante="this.idEntFac"
        :movProvIdOp="this.movProvIdOp"
      ></EnviarOrdenesPagoEmail>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import enums from "@/utils/enums/index.js";
import VerEnviosPreviosOP from "@/components/modules/proveedores/ordenesPago/VerEnviosPreviosOP.vue";
import EnviarOrdenesPagoEmail from "@/components/modules/proveedores/ordenesPago/EnviarOrdenesPagoEmail";

export default {
  name: "VerOrdenDePago",
  components: {
    PageHeader,
    GoBackBtn,
    VerEnviosPreviosOP,
    EnviarOrdenesPagoEmail
  },
  created() {
    this.setParamsMultimoneda();
    this.setDetalleOrdenPago();
  },
  data() {
    return {
      loadingBtn: false,
      routeToGo: this.$route.params.goBackName,
      infoIcon: enums.icons.SNB_INFO,
      title: enums.titles.VER_ORDEN_DE_PAGO,
      proveedor: null,
      idEntFac: null,
      idEnvios: null,
      openVerEnviosPrevios: false,
      cuit: null,
      tipoIva: null,
      direccion: null,
      modalidadPago: null,
      destinoPago: null,
      tipoComprobante: null,
      comprobante: null,
      fecha: null,
      fechaContable: null,
      importeNeto: null,
      iva: null,
      percepcionIva: null,
      percepcionIIBB: null,
      impuestosInternos: null,
      otrosConceptos: null,
      importeTotal: null,
      mail: "",
      idOpEnvPrevios: null,
      movProvIdOp: null,
      openModalEnviarOrdenes: false,
      paramGralMultimoneda: true,
      haveCotiza: false,
      monedaComprobante: null,
      monedaConsultaSelected: null,
      fechaCotizacion: null,
      monedasConsultaItems: [],
      comprobantesCanceladosList: [],
      headersComprobantesCancelados: [
        {
          text: "Fecha",
          value: "fecha",
          sortable: false,
          align: "start"
        },
        {
          text: "Tipo comprobante",
          value: "tipoCompNombre",
          sortable: false,
          align: "start"
        },
        {
          text: "Comprobante",
          value: "compNro",
          sortable: false,
          align: "start"
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          align: "end"
        },
        {
          text: "Cancelado",
          value: "importeCancelado",
          sortable: false,
          align: "end"
        },
        {
          text: "Saldo",
          value: "saldo",
          sortable: false,
          align: "end"
        },
        {
          text: "Cancelado O.P.",
          value: "canceladoOP",
          sortable: false,
          align: "end"
        }
      ],
      retencionesList: [],
      headersRetenciones: [
        {
          text: "Retención",
          value: "tipoValorNombre",
          sortable: false,
          align: "start"
        },
        {
          text: "Número",
          value: "numero",
          sortable: false,
          align: "start"
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end"
        }
      ],
      valoresList: [],
      headersValores: [
        {
          text: "Valor",
          value: "tipoValorNombre",
          sortable: false,
          align: "start"
        },
        {
          text: "Número",
          value: "numero",
          sortable: false,
          align: "start"
        },
        {
          text: "Fecha",
          value: "fechaValor",
          sortable: false,
          align: "start"
        },
        {
          text: "Banco",
          value: "bancoNombre",
          sortable: false,
          align: "start"
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end"
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      getDetalleOrdenPago: "proveedores/getDetalleOrdenPago",
      getComprobanteProveedorById: "proveedores/getComprobanteProveedorById",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      imprimirArchivoOrdenesPagoPdf:
        "proveedores/imprimirArchivoOrdenesPagoPdf",
      setAlert: "user/setAlert"
    }),
    async setParamsMultimoneda() {
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
      const dataMonedas = await this.getMonedasTaxonomy("habListado");
      this.monedasConsultaItems = dataMonedas;
    },
    async setDetalleOrdenPago() {
      const idOp = this.$route.params.movProvId;
      this.haveCotiza = false;
      if (this.$route.params.isCtaCte) {
        const data = {
          mProvId: this.$route.params.movProvId,
          monedaId: this.$route.params.monedaToDetalle?.id,
          fechaCotiza: this.$route.params.fechaCotizaToDetalle
        };
        this.fechaCotizacion = this.formatDate(
          this.$route.params.fechaCotizaToDetalle
        );
        const response = await this.getComprobanteProveedorById(data);
        if (response.cabecera.haveCotiza == 0) this.haveCotiza = true;
        this.idEntFac = response.cabecera.entFacId;
        this.proveedor = response.cabecera.proveedorNombre;
        this.cuit = response.cabecera.cuit;
        this.tipoIva = response.cabecera.tipoIvaNombre;
        this.direccion = response.cabecera.direccion;
        this.modalidadPago = response.cabecera.modalidadPago;
        this.destinoPago = response.cabecera.detinoPago;
        this.tipoComprobante = response.cabecera.tcompNombre;
        this.comprobante = response.cabecera.compNro;
        this.fecha = response.cabecera.fecha;
        this.fechaContable = response.cabecera.fechaContable;
        this.importeNeto = response.cabecera.neto;
        this.iva = response.cabecera.iva;
        this.percepcionIva = response.cabecera.percepcionIva;
        this.percepcionIIBB = response.cabecera.percepcionIB;
        this.impuestosInternos = response.cabecera.impuestosInternos;
        this.otrosConceptos = response.cabecera.otrosConceptos;
        this.importeTotal = response.cabecera.total;
        this.monedaComprobante = response.cabecera.monedaNombre;
        this.monedaConsultaSelected = this.$route.params.monedaToDetalle;
        //datos listas
        this.retencionesList = response.retenciones;
        this.valoresList = response.valores;
        this.comprobantesCanceladosList = response.comprobantesCancelados;
      } else {
        const response = await this.getDetalleOrdenPago(idOp);
        this.idEntFac = response.cabecera.entFacId;
        this.proveedor = response.cabecera.proveedorNombre;
        this.cuit = response.cabecera.cuit;
        this.tipoIva = response.cabecera.tipoIvaNombre;
        this.direccion = response.cabecera.direccion;
        this.modalidadPago = response.cabecera.modalidadPago;
        this.destinoPago = response.cabecera.detinoPago;
        this.tipoComprobante = response.cabecera.tcompNombre;
        this.comprobante = response.cabecera.compNro;
        this.fecha = response.cabecera.fecha;
        this.fechaContable = response.cabecera.fechaContable;
        this.importeNeto = response.cabecera.neto;
        this.iva = response.cabecera.iva;
        this.percepcionIva = response.cabecera.percepcionIva;
        this.percepcionIIBB = response.cabecera.percepcionIB;
        this.impuestosInternos = response.cabecera.impuestosInternos;
        this.otrosConceptos = response.cabecera.otrosConceptos;
        this.importeTotal = response.cabecera.total;
        //datos listas
        this.retencionesList = response.retenciones;
        this.valoresList = response.valores;
        this.comprobantesCanceladosList = response.comprobantesCancelados;
      }
      this.movProvIdOp = idOp;
      this.mail = this.$route.params.email;
    },
    async setComprobanteByMoneda() {
      this.haveCotiza = false;
      const data = {
        mProvId: this.$route.params.movProvId,
        monedaId: this.monedaConsultaSelected.id,
        fechaCotiza: this.$route.params.fechaCotizaToDetalle
      };
      const response = await this.getComprobanteProveedorById(data);
      if (response.cabecera.haveCotiza == 0) this.haveCotiza = true;
      this.importeNeto = response.cabecera.neto;
      this.iva = response.cabecera.iva;
      this.percepcionIva = response.cabecera.percepcionIva;
      this.percepcionIIBB = response.cabecera.percepcionIB;
      this.impuestosInternos = response.cabecera.impuestosInternos;
      this.otrosConceptos = response.cabecera.otrosConceptos;
      this.importeTotal = response.cabecera.total;
      //datos listas
      this.retencionesList = response.retenciones;
      this.valoresList = response.valores;
      this.comprobantesCanceladosList = response.comprobantesCancelados;
    },
    enviarMail() {
      this.openModalEnviarOrdenes = true;
    },
    closeAndReload() {
      this.openModalEnviarOrdenes = false;
    },
    async printOrdenPago() {
      const data = {
        id: this.$route.params.movProvId,
        tipoImpresion: "C",
        fileName: `O.P. - ${this.comprobante}`,
        browser: this.getBrowserInfo().charAt(0)
      };
      try {
        await this.imprimirArchivoOrdenesPagoPdf(data);
      } catch (error) {}
    },
    async printRetencion(id) {
      const data = {
        id: id,
        tipoImpresion: "R",
        fileName: `Retención de ganancias - O.P. - ${this.comprobante}`,
        browser: this.getBrowserInfo().charAt(0)
      };
      try {
        await this.imprimirArchivoOrdenesPagoPdf(data);
      } catch (error) {}
    },
    VerEnviosPrevios() {
      this.idOpEnvPrevios = this.$route.params.movProvId;
      this.idEnvios = this.idOpEnvPrevios;
      this.openVerEnviosPrevios = true;
    },
    closeVerEnviosPrevios() {
      this.openVerEnviosPrevios = false;
    },
    getBrowserInfo() {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return "IE " + (tem[1] || "");
      }
      if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null)
          return tem
            .slice(1)
            .join(" ")
            .replace("OPR", "Opera");
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(" ");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
  }
};
</script>
<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
.blue-text {
  color: blue;
  text-decoration: underline blue;
}
.btnDisabled {
  color: #100f0f99;
}
.invalidConfigText {
  color: red;
}
</style>
