var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v("Históricos de envíos de órdenes de pago")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"expanded":_vm.expanded,"headers":_vm.headers,"item-value":"text","items":_vm.detalleEnvioOP,"item-key":"id","loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.fechaEnvio",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.estado == 'Error' ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.fechaEnvio))])]}},{key:"item.comprobante",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.estado == 'Error' ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.comprobante))])]}},{key:"item.usuario",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.estado == 'Error' ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.usuario))])]}},{key:"item.destinatario",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.estado == 'Error' ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.destinatario))])]}},{key:"item.proceso",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.estado == 'Error' ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.proceso))])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.estado == 'Error' ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.estado))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pt-2",attrs:{"colspan":headers.length}},[_c('p',{staticClass:"mb-0",staticStyle:{"color":"black"}},[_vm._v("Detalle del error:")]),_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.obs))])])]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"to-right",attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cerrar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }