<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Históricos de envíos de órdenes de pago</v-card-title
      >
      <v-data-table
        :expanded="expanded"
        :headers="headers"
        item-value="text"
        :items="detalleEnvioOP"
        class="elevation-1"
        item-key="id"
        :loading="isLoading"
      >
        <template v-slot:[`item.fechaEnvio`]="{ item }">
          <span :style="item.estado == 'Error' ? 'color:red' : ''">
            {{ item.fechaEnvio }}</span
          >
        </template>
        <template v-slot:[`item.comprobante`]="{ item }">
          <span :style="item.estado == 'Error' ? 'color:red' : ''">
            {{ item.comprobante }}</span
          >
        </template>
        <template v-slot:[`item.usuario`]="{ item }">
          <span :style="item.estado == 'Error' ? 'color:red' : ''">
            {{ item.usuario }}</span
          >
        </template>
        <template v-slot:[`item.destinatario`]="{ item }">
          <span :style="item.estado == 'Error' ? 'color:red' : ''">
            {{ item.destinatario }}</span
          >
        </template>
        <template v-slot:[`item.proceso`]="{ item }">
          <span :style="item.estado == 'Error' ? 'color:red' : ''">
            {{ item.proceso }}</span
          >
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <span :style="item.estado == 'Error' ? 'color:red' : ''">
            {{ item.estado }}</span
          >
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pt-2">
            <p style="color:black" class="mb-0">Detalle del error:</p>
            <p style="color:red">{{ item.obs }}</p>
          </td>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()" class="to-right"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import template from "../../../../views/modules/template/template.vue";
export default {
  components: { template },
  name: "VerEnviosPreviosOP",
  props: {
    idEnvios: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    checkIcon: enums.icons.CHECK_OUTLINE,
    isLoading: false,
    chevronUpIcon: enums.icons.CHEVRON_UP,
    chevronDownIcon: enums.icons.CHEVRON_DOWN,
    detalleEnvioOP: [],
    expanded: [],
    verObs: "",
    banderaObs: false,
    headers: [
      {
        text: "Fecha de envío",
        value: "fechaEnvio",
        sortable: false
      },
      {
        text: "Comprobante",
        value: "comprobante",
        sortable: false,
        align: "left"
      },
      {
        text: "Usuario",
        value: "usuario",
        sortable: false,
        align: "left"
      },
      {
        text: "Destinatario",
        value: "destinatario",
        sortable: false,
        align: "left"
      },
      {
        text: "N° de proceso",
        value: "proceso",
        sortable: false,
        align: "left"
      },
      {
        text: "Estado",
        value: "estado",
        sortable: false,
        align: "end"
      }
    ],
    allowedActions: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setEnviosPrevios();
  },
  methods: {
    ...mapActions({
      getHistorialEnviosOP: "proveedores/getHistorialEnviosOP"
    }),
    async setEnviosPrevios() {
      this.isLoading = true;
      const data = await this.getHistorialEnviosOP(this.idEnvios);
      this.detalleEnvioOP = data;
      let tieneError  = this.detalleEnvioOP.filter(x => x.obs?.length > 0)
      this.expanded = tieneError
      this.isLoading = false;
    },

    closeModal() {
      this.$emit("closeVerEnviosPrevios");
    }
  }
};
</script>

<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}
</style>
